import React from "react"
export default function SessionData() {
  return (
    <>
      <table className="markethourtbl table table-striped live-matrix">
        <thead style={{ backgroundColor: "#666666", color: "#ffffff" }}>
          <tr>
            <th style={{ textAlign: "center" }}>Warrant underlying</th>
            <th style={{ textAlign: "center" }}>Morning session</th>
            <th style={{ textAlign: "center" }}>Afternoon session</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }} >Malaysia shares <br className="xs-view" />and FBM KLCI</td>
            <td style={{ textAlign: "center" }}>9:00am to 12:29pm</td>
            <td style={{ textAlign: "center" }}>2:30pm to 4:43pm</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>U.S. indices</td>
            <td style={{ textAlign: "center" }}>9:00am to 12:29pm</td>
            <td style={{ textAlign: "center" }}>2:30pm to 4:43pm</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Nikkei Stock Average (Nikkei 225) Index</td>
            <td style={{ textAlign: "center" }}>9:00am to 12:29pm</td>
            <td style={{ textAlign: "center" }}>2:30pm to 2:53pm, <br /> 3:10pm to 4:43pm</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Hong Kong indices</td>
            <td style={{ textAlign: "center" }}>9:15am to 11:58am</td>
            <td style={{ textAlign: "center" }}>2:30pm to 4:28pm</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }} >Hong Kong shares, iShares China A50 ETF<br className="" />and SPDR Gold Trust</td>
            <td style={{ textAlign: "center" }}>9:30am to 11:58am</td>
            <td style={{ textAlign: "center" }}>2:30pm to 3:58pm</td>
          </tr>
        </tbody>
      </table>

    </>
  )
}
